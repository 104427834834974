.historicoCampoCadastro {
    padding: 50px 30px;
    .table {
        margin-top: 2.5rem;
        background: #fafafa;
        border: 1px solid #e6e8ea;
        border-radius: 10px;
        min-height: 15rem;
        margin-bottom: 1rem;
        table {
            table-layout: fixed;
            border-collapse: collapse;
            width: 100%;
            text-align: left;
            font-size: 12px;
            tr {
                height: 2rem;
                border-bottom: 1px solid #e6e8ea;
                td,
                th {
                    &:first-child {
                        padding: 1rem;
                    }
                }
            }
        }
    }
}
.horaAlteracao {
    padding-left: 1rem;
}
.etapaECampo {
    display: flex;
    margin-top: 1.5rem;
    > * {
        flex: 1;
        display: flex;
        .label {
            font-weight: bold;
            margin-right: 1rem;
        }
    }
}
