@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.botaoEditar {
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 3px;
    padding: $spacing-1x;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: $cor-acento-3;

        svg {
            fill: $cor-contraste-3;
        }
    }

    svg {
        width: auto;
        height: 20px;
        fill: $cor-acento-3;
    }
}
