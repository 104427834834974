@import "assets/styles/_dimensions";

.addImageIcon {
    margin-left: 0.3em;
    width: 1.3em;
    height: 1.3em;
}
.addImageLabel {
    display: flex;
    padding-top: $spacing-1x;
    cursor: pointer;
}
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.leftAlign {
    align-items: flex-start;
}

.button {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    &:hover {
        opacity: 0.7;
    }
}
