@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.listagemFilaLeads {
    .filtrosAplicados {
        margin-right: auto;
    }
    .botaoEnviarConvite {
        border-radius: 5px;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        &.disabled {
            > svg {
                filter: grayscale(100%);
                opacity: 30%;
                cursor: default;
            }
        }
        > svg {
            margin: 0.1rem;
            scale: 0.7;
            path {
                &:last-child {
                    stroke: none;
                    fill: #6759f2;
                }
            }
        }
        &:hover {
            &:not(.disabled) {
                background-color: #6759f2;

                > svg {
                    path {
                        &:last-child {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
    .barraAcao {
        display: flex;
        margin: $spacing-4x 0;
    }
    .botoesAcao {
        display: flex;
        justify-content: flex-end;

        > * {
            min-width: max-content;
            max-height: 3rem;
            margin-left: 1rem;
            outline: none;
            text-decoration: none;
            border: 2px solid $cor-acento-3;
            border-radius: 3px;
            padding: $spacing-2x $spacing-3x;
            background-color: $cor-contraste-3;
            color: $cor-acento-3;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                background-color: $cor-acento-3;
                color: $cor-contraste-3;
            }
        }
    }
    div.labelConviteEnviado {
        &:first-child {
            white-space: normal;
        }
        color: #6759f2;
        font-size: 0.8rem;
        text-align: center;
    }
    .horaAlteracao {
        margin-left: 0.5rem;
    }
    .nomeUsuarioAnalise {
        font-size: 10px;
    }
    .listagemCadastros {
        .tabelaListagem {
            padding: 10px 0;
        }
    }
}
