.form {
    text-align: left;
    margin: 3rem 0 1rem 0;
}
.camposForm {
    margin-bottom: 1rem;
    display: flex;
    > * {
        flex: 1;
        &:not(:last-child) {
            margin-right: 2rem;
        }
    }
}
.botoes {
    display: flex;
    justify-content: flex-end;
    .botaoCancelar {
        background-color: #bdbdbd;
        margin-right: 3rem;
    }
}
