@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.listagemCadastroPrestadores {
    .editarConfig {
        outline: none;
        background-color: transparent;
        border: none;
        border-radius: 3px;
        padding: $spacing-1x;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;

            svg {
                fill: $cor-contraste-3;
            }
        }

        svg {
            width: auto;
            height: 20px;
            fill: $cor-acento-3;
        }
    }
    .barraAcao {
        margin: $spacing-4x 0;
        display: flex;
        justify-content: space-between;
        .adicionar {
            display: flex;
            justify-content: flex-end;

            > * {
                margin-left: 1rem;
                outline: none;
                text-decoration: none;
                border: 2px solid $cor-acento-3;
                border-radius: 3px;
                padding: $spacing-2x $spacing-3x;
                background-color: $cor-contraste-3;
                color: $cor-acento-3;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: $cor-acento-3;
                    color: $cor-contraste-3;
                }
            }
        }
    }
    .filtroStatus {
        grid-column-start: 4;
        grid-row-start: 2;
    }
}
