.dashboardOnboarding {
    letter-spacing: 0.07rem;
    > h2 {
        margin-top: 0;
        font-family: "Gibson Semibold";
        font-weight: 100;
    }
    .dashboardGrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
        gap: 55px 44px;
    }
}
