@import "assets/styles/_dimensions";

.omnijusFieldQualificacao {
    grid-column: span 2;
    .selects {
        display: grid;
        gap: $spacing-4x;
        grid-template-columns: repeat(2, 1fr);
        .tempoEAdd {
            display: flex;
            align-items: flex-end;
            .tempo {
                flex: 1;
            }
        }
    }
    .linhaQualificacaoga {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $spacing-4x;
        margin: $spacing-2x 0;
        .direita {
            display: flex;
            .meses {
                flex: 1;
            }
        }
    }
    .error {
        color: red;
        margin: $spacing-3x 0 0 $spacing-2x;
    }
    .iconeAdd {
        cursor: pointer;
    }
}
