@import "assets/styles/colors";
@import "assets/styles/dimensions";

@font-face {
    font-family: "Gibson Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Gibson Regular"), url("./assets/fonts/GibsonRegular.woff") format("woff");
}

@font-face {
    font-family: "Gibson Italic";
    font-style: italic;
    font-weight: normal;
    src: local("Gibson Italic"), url("./assets/fonts/GibsonItalic.woff") format("woff");
}

@font-face {
    font-family: "Gibson Light";
    font-style: normal;
    font-weight: normal;
    src: local("Gibson Light"), url("./assets/fonts/GibsonLight.woff") format("woff");
}

@font-face {
    font-family: "Gibson Light Italic";
    font-style: italic;
    font-weight: normal;
    src: local("Gibson Light Italic"), url("./assets/fonts/GibsonLightIt.woff") format("woff");
}

@font-face {
    font-family: "Gibson SemiBold";
    font-style: normal;
    font-weight: normal;
    src: local("Gibson SemiBold"), url("./assets/fonts/GibsonSemiBd.woff") format("woff");
}

@font-face {
    font-family: "Gibson Semibold Italic";
    font-style: italic;
    font-weight: normal;
    src: local("Gibson Semibold Italic"), url("./assets/fonts/GibsonSemiBdIt.woff") format("woff");
}

@font-face {
    font-family: "Gibson Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Gibson Bold"), url("./assets/fonts/GibsonBold.woff") format("woff");
}

@font-face {
    font-family: "Gibson Bold Italic";
    font-style: italic;
    font-weight: normal;
    src: local("Gibson Bold Italic"), url("./assets/fonts/GibsonBoldIt.woff") format("woff");
}

* {
    box-sizing: border-box;
}

html {
    font-size: 14px;
}

body {
    margin: 0;
    background-color: $cor-contraste-13;
    font-family: "Gibson Regular";
    color: $cor-contraste-1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    font-family: "Gibson Regular";
}

.container {
    margin: 0 auto;
    max-width: 1360px;
    padding: $spacing-4x;

    .titulo-destaque {
        text-transform: uppercase;
    }
}

.cor-acento-1-gradient {
    background: linear-gradient(142.03deg, $cor-acento-1 9.59%, $cor-acento-1 102.23%);
}

.cor-acento-2-gradient {
    background: linear-gradient(142.03deg, $cor-acento-2 9.59%, $cor-acento-2 102.23%);
}

.cor-acento-3-gradient {
    background: linear-gradient(142.03deg, $cor-acento-3 9.59%, $cor-acento-3 102.23%);
}

.cor-acento-4-gradient {
    background: linear-gradient(142.03deg, $cor-acento-4 9.59%, $cor-acento-4 102.23%);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.span-column-2 {
    grid-column: span 2;
}

.swal2-confirm {
    background-color: $cor-acento-1 !important;
}

.swal2-cancel {
    background-color: $cor-contraste-10 !important;
}

.swal2-close {
    outline: none;
}

.flash {
    animation: blinker 100ms linear;
}

.react-toggle--checked .react-toggle-track {
    background-color: $cor-acento-1;
}

.react-toggle-thumb {
    outline: none;
}

.react-toggle--checked .react-toggle-thumb {
    border-color: $cor-contraste-1;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}