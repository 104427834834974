.linksExperienciaProfissional {
    display: flex;
    flex-direction: row;
    a {
        color: #6759f2;
        text-decoration: none;
    }
    > * {
        flex: 1;
    }
}
.camposExperienciaProfissional {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px 30px;
    border-radius: 5px;
    border: 1px solid #e6e8ea;
    padding-bottom: 2rem;
    padding-top: 1rem;
    row-gap: 2rem;
    > div {
        margin-bottom: 0;
        margin-top: 0;
    }
}
.campoDescricao {
    grid-row: span 3;
}
