@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.listar-templates{
    > :not(:first-child){
        margin-top: $spacing-2x;
    }

    .formulario{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $spacing-2x;
    }

    .adicionar-template{
        display: flex;
        justify-content: flex-end;
        padding-top: $spacing-6x;

        a{
            outline: none;
            border: 2px solid $cor-acento-3;
            border-radius: 3px;
            padding: $spacing-2x $spacing-3x;
            background-color: $cor-contraste-3;
            color: $cor-acento-3;
            font-weight: bold;
            text-decoration: none;
            cursor: pointer;

            &:hover{
                background-color: $cor-acento-3;
                color: $cor-contraste-3;
            }
        }
    }

    .editar-template{
        outline: none;
        background-color: transparent;
        border: none;
        border-radius: 3px;
        padding: $spacing-1x;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover{
            background-color: $cor-acento-3;

            svg{
                fill: $cor-contraste-3;
            }
        }

        svg{
            width: auto;
            height: 20px;
            fill: $cor-acento-3;
        }
    }
}