@media screen and (min-width: 764px) {
    .cardsCadastro {
        grid-column: span 2;
    }
}
.cardsCadastro {
    background: #b4b4b41a;
    border: 1px solid #e6e8ea;
    border-radius: 5px;
    > h3 {
        text-align: center;
        font-size: 20px;
        line-height: 80px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .cards {
        padding: 0 32px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 30px;
    }
    .total {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 30px;
        color: #CCC;
        align-items: center;
        > strong {
            font-size: 40px;
            margin-top: 10px;
            color: #696969;
        }
        
    }
}
