@import "assets/styles/_colors";

.loading {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
    }

    &.errorMessage {
        color: $cor-acento-5;
        padding: 15px 10px;
        border: solid 1px darken($color: $cor-acento-5, $amount: 20);
        background: lighten($color: $cor-acento-5, $amount: 42);
        border-radius: 5px;
    }
    &.loadingInline {
        display: inline-flex;

        img {
            width: 1.2em;
            height: 1.2em;
        }
    }
}
