@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.btnPrimary {
    background-color: $cor-acento-1;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: $cor-acento-1, $amount: 5);
    }

    &:disabled {
        background-color: $cor-contraste-9;
    }
}

.btn-primary-small {
    background-color: $cor-acento-1;
    border-radius: 5px;
    padding: $spacing-1x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: $cor-acento-1, $amount: 5);
    }
}
