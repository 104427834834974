@import "assets/styles/_dimensions";

.formularioIdentificacao {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-3x;
    .associado {
        grid-column: span 2;
    }
}