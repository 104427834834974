@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.dropdown {
    position: relative;
    cursor: pointer;
    display: flex;

    .menu {
        background-color: $cor-contraste-3;
        box-shadow: 0px 0px 5px $cor-contraste-9;
        position: absolute;
        right: 0;
        top: calc(100% + #{$spacing-1x});
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 100px;

        :not(:first-child) {
            border-top: 1px solid $cor-contraste-2;
        }

        li {
            padding: $spacing-3x;
            text-align: center;
            cursor: pointer;

            &:hover {
                background-color: $cor-contraste-4;
            }
        }
    }
}
