@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.detalhar-cadastro-prestador-pf{
    > :not(:first-child){
        margin-top: $spacing-2x;
    }

    p{
        margin: 0;
    }

    .link{
        background-color: transparent;
        border: none;
        outline: none;
        text-decoration: underline;
        cursor: pointer;
    }

    .prefixo{
        font-weight: bold;
        font-size: 1.2em;
        margin-right: $spacing-1x;
    }
    
    .identificacao{
        display: flex;

        .dados{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: $spacing-2x;
        }
        .perfil{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $spacing-4x;

            .sem-foto{
                width: 80px;
                height: 80px;
                background-color: $cor-contraste-5;
            }
        }
    }

    .informacoes-profissionais{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacing-2x;
    }

    .dados-bancarios{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $spacing-2x;
    }

    .acao-voltar{
        display: flex;
        justify-content: flex-end;
    }
}