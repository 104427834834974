.cabecalho {
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;
    > * {
        max-width: max-content;
        flex: 1;
        display: flex;
        .label {
            font-weight: bold;
            margin-right: 1rem;
        }
        &:not(:first-child) {
            margin-left: 1rem;
        }
    }
}

.campoForm {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
    align-items: center;
    > * {
        &:last-child {
            flex: 1;
        }
    }
    .label {
        width: 8rem;
        font-weight: bold;
        margin-right: 1rem;
    }
}

button.closeButton {
    padding-top: 1.3rem;
    padding-right: 2rem;
    &:focus {
        box-shadow: none;
    }
}

