@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.template-utilizacao-field{
    .utilizacao{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        gap: $spacing-2x;
        padding: $spacing-2x;

        > label {
            flex: 1;
        }

        .remover{
            border: none;
            border-radius: 3px;
            padding: $spacing-2x;
            background-color: transparent;
            outline: none;
            cursor: pointer;

            &:hover{
                background-color: $cor-contraste-2;
            }
        }
    }
    .adicionar-registro{
        .button{
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: $spacing-2x;
            padding: $spacing-2x;
            background-color: transparent;
            border: 2px solid $cor-acento-1;
            border-radius: 5px;
            color: $cor-acento-1;
            outline: none;
            cursor: pointer;

            &:hover{
                background-color: $cor-acento-1;
                color: $cor-contraste-3;
                
                .icon{
                    path{
                        fill: $cor-contraste-3;
                    }
                }
            }

            .label{
                font-size: 1.2em;
                font-weight: bold;
                cursor: pointer;
            }
            .icon{
                path{
                    fill: $cor-acento-1;
                }
            }
        }
    }
}