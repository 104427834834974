@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.campo {
    margin: 15px 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    >* {
        width: 100%;
    }
}

.campoCadastro {
    .valorAcoes {
        display: flex;
        height: 100%;
    }

    .valor {
        flex: 1;
        padding-left: 1rem;
        font-size: 14px;

        display: block;
        background-color: #fafafa;
        border-bottom: 1px solid black;
        line-height: 3rem;
        min-height: 3rem;
        color: #545454;
        height: 100%;
    }
}

.rotulo {
    font-size: 15px;
    margin-bottom: 1rem;
    display: block;
    //font-weight: bold;
}

.textArea {
    min-width: 400px;
    width: 100%;
    height: 200px;
    resize: none;
}

.success {
    border-color: green;
    outline-color: green;
}

.danger {
    border-color: red;
    outline-color: red;
}