@import "assets/styles/_dimensions";

.adicionarCadastroPrestador {
    > :not(:last-child) {
        margin-bottom: $spacing-4x;
    }
}
.linhaSubmit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.table {
    border-collapse: collapse;
    width: 100%;
    line-height: 2;
    tr {
        td {
            border-bottom: solid 1px lightgray;
        }
    }
}

.botaoIcone {
    margin-top: $spacing-2x;
    cursor: pointer;
    background: unset;
    border: none;
    svg {
        width: 16px;
        height: 16px;
    }
    &:hover {
        opacity: 0.65;
    }
}
.botaoDownload {
    cursor: pointer;
    background: unset;
    border: none;
    &:hover {
        opacity: 0.65;
    }
}
.colCep {
    width: 8rem;
}
.colIcones {
    width: 6rem;
}
.colData {
    width: 8rem;
}
.colTipoPeriodo {
    width: 9rem;
}
.colPeriodosCursados {
    width: 9rem;
}
.colTipoBanco {
    width: 10rem;
}
.colAgencia {
    width: 10rem;
}
.linhaSubmit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modalForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-3x;
    .linhaSubmit {
        grid-column: span 4;
        justify-content: center;
    }
    &.grid3columns {
        grid-template-columns: repeat(3, 1fr);
        .linhaSubmit {
            grid-column: span 3;
        }
    }
}

.error {
    color: red;
    margin: 0 $spacing-2x;
}
