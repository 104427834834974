@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.navBar {
    height: 80px;
    border-bottom: 1px solid #eaedf3;
    display: flex;
    align-content: center;
    background-color: $cor-contraste-3;

    .navLinkDashboard {
        background-color: $cor-acento-1;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacing-8x;
    }

    .navItems {
        display: flex;
        flex: 1;
    }
    .link,
    .navLink {
        color: $cor-contraste-1;
        text-decoration: none;
        &.active {
            border-bottom: 3px solid $cor-acento-1;
            color: $cor-acento-1;
        }
    }
    .navLink {
        display: flex;
        align-items: center;
        border-bottom: 3px solid transparent;
        margin-left: $spacing-8x;
        font-weight: bold;
        font-weight: 600;
        font-size: 16px;
        > * {
            font-size: 1rem;
        }
    }

    .navRight {
        margin-left: auto;
        display: flex;
        align-items: stretch;

        .item {
            padding: 0 $spacing-8x;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                border-right: 1px solid $cor-contraste-6;
            }

            .searchGroup {
                background-color: $cor-contraste-4;
                display: flex;
                align-items: stretch;
                padding: $spacing-3x $spacing-5x;
                border-radius: 5px;

                input {
                    width: 360px;
                    border: none;
                    outline: none;
                    background-color: $cor-contraste-4;
                    margin-left: $spacing-4x;
                    font-weight: normal;
                    font-size: 16px;
                }
            }

            .notification {
                position: relative;
                cursor: pointer;

                &:first-child {
                    margin-right: $spacing-8x;
                }

                .circle {
                    position: absolute;
                    right: -2px;
                    top: -2px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: $cor-acento-5;
                }
            }

            .perfil {
                display: flex;
                align-items: center;

                .user {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: $cor-contraste-5;
                    margin-right: $spacing-2x;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    color: $cor-contraste-3;
                }
            }
        }
    }
}
.submenu {
    background-color: $cor-contraste-3;
    box-shadow: 0px 0px 5px $cor-contraste-9;
    position: absolute;
    right: -0.7rem;
    top: calc(100% - #{$spacing-2x});
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 100px;

    :not(:first-child) {
        border-top: 1px solid $cor-contraste-2;
    }

    li {
        > .link {
            padding: $spacing-3x;
            text-align: center;
            cursor: pointer;
            display: flex;
            justify-content: center;
        }

        &:hover {
            background-color: $cor-contraste-4;
        }
    }
}
