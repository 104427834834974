@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.filtrosAplicados {
    margin-right: auto;
}
.botaoAnalisar {
    border-radius: 5px;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    &.disabled {
        > svg {
            filter: grayscale(100%);
            opacity: 30%;
            cursor: default;
        }
    }
    > svg {
        margin: 0.1rem;
        scale: 0.7;
        g {
            &:last-child {
                stroke: none;
                fill: #6759f2;
            }
        }
    }
    &:hover {
        &:not(.disabled) {
            background-color: #6759f2;

            > svg {
                g {
                    &:last-child {
                        fill: white;
                    }
                }
            }
        }
    }
}
.barraAcao {
    display: flex;
    margin-top: $spacing-2x;
    margin-bottom: $spacing-4x;
}
.botoesAcao {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    > * {
        margin-left: 1rem;
    }
    .importarConvites {
        display: flex;
        flex-direction: column;
        .linkModelo {
            margin-left: 0.2rem;
            font-size: 0.8rem;
            cursor: pointer;
            color: $cor-acento-3;
            margin-bottom: 0.3rem;
            text-align: left;
            border: none;
            padding: 0;
            background-color: transparent;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .botaoAcao {
        min-width: max-content;
        max-height: 3rem;
        outline: none;
        text-decoration: none;
        border: 2px solid $cor-acento-3;
        border-radius: 3px;
        padding: $spacing-2x $spacing-3x;
        background-color: $cor-contraste-3;
        color: $cor-acento-3;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;
            color: $cor-contraste-3;
        }
    }
}

.statusConvite {
    text-align: center;
    .dataHoraUtilizacao {
        font-size: 6pt;
        text-align: center;
        .horaUtilizacao {
            margin-left: 0.3rem;
        }
    }
}
.acoesConvite {
    display: flex;
    > * {
        scale: 0.8;
    }
    .iconeAcao {
        outline: none;
        background-color: transparent;
        border: none;
        border-radius: 3px;
        padding: $spacing-1x;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;

            svg,
            svg > path {
                fill: $cor-contraste-3;
            }
        }

        svg,
        svg > path {
            width: auto;
            height: 20px;
            fill: $cor-acento-3;
        }
        &:disabled {
            cursor: default;
            filter: grayscale(100%);
            opacity: 0.4;
            &:hover {
                background: none;
                svg,
                svg > path {
                    fill: $cor-acento-3;
                }
            }
        }
    }
}
.fileInput {
    display: none;
}
.erroImportacaoPopup {
    text-align: left;
}
.horaAlteracao {
    margin-left: 0.5rem;
}
