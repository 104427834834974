.botoesForm {
    display: flex;
    justify-content: flex-end;
    > * {
        &:not(:last-child) {
            margin-right: 2rem;
        }
    }
    .botaoAprovarCadastro{
        background-color: #0FA88F;
    }
    margin-bottom: 1rem;
}
