.historicoCadastro {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.tabelaHistorico {
    background: #fafafa;
    border: 1px solid #e6e8ea;
    border-radius: 10px;
    font-size: 12px;
    margin-top: 6px;
    max-height: 25rem;
    overflow-y: auto;
    > table {
        padding: 20px;
        border-collapse: collapse;
        text-align: left;
        width: 100%;
        table-layout: fixed;
        overflow: hidden;
        tr {
            padding: 20px;
            td,
            th {
                padding-top: 20px;
                padding-bottom: 20px;
                &:first-child {
                    padding-left: 20px;
                }
            }
        }
        tbody {
            tr {
                &:not(:last-child) {
                    border: 1px solid #e6e8ea;
                    border-width: 1px 0;
                }
            }
        }
    }
}

.hora {
    margin-left: 20px;
}
