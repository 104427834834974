.cardCadastro {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.headerCardCadastro {
    font-size: 17px;
    font-weight: bold;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 2rem;
}
.bodyCardCadastro {
    padding-bottom: 20px;
}

.fundoVermelho {
    background-color: #ffc3c3;
} 

.fundoAmarelo {
    background-color: #fcffdb;
}

.iconeInconsistencia {
    margin-right: 0.5rem;
    .iconeVermelho {
        path:last-child {
            fill: #e30018;
        }
    }
    .iconeAmarelo {
        path:last-child {
            fill: #ffaf5c;
        }
    }

    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    height: 18px;
    > svg {
        width: 18px;
        height: 18px;
        > path {
            &:last-child {
                fill: #6759f2;
            }
        }
    }
}
