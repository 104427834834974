@import "assets/styles/_dimensions";

.configurarTemplateCliente {
    .formulario {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $spacing-4x;
    }
    .cards {
        > :not(:last-child) {
            margin-bottom: 2em;
        }
    }
    .formularioTemplate {
        display: grid;
        grid-template-columns: repeat(5, 1fr) 2em;
        gap: $spacing-4x;
        margin: $spacing-4x 0;
        .iconeExcluir {
            display: flex;
            align-items: flex-end;
            > svg {
                cursor: pointer;
            }
        }
    }
    .tabelaEmpresas {
        colgroup {
            :first-child {
                width: 3em;
            }
        }
    }
    .iconeAdicionar {
        display: flex;
        justify-content: flex-end;
        padding: $spacing-3x;
        > svg {
            cursor: pointer;
        }
    }

    table {
        width: 100%;
    }
    .checkboxEmpresa {
        :global(.form-field-control) {
            min-width: 2em;
        }
    }
    .linhaSubmit {
        display: flex;
        justify-content: flex-end;
        > * {
            margin: 0 0 0 3em;
        }
    }
}
