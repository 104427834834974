@import "assets/styles/_colors";

.authSplash {
    width: 100vw;
    height: 100vh;
    background-color: $cor-acento-1;
    display: flex;
    align-items: center;
    justify-content: center;
}
