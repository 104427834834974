.omnijusLogo {
    path {
        fill: white;
    }

    &.pulse {
        animation: pulse 2s linear infinite;
    }

    @keyframes pulse {
        50% {
            opacity: 0;
        }
    }
}
