.tabelaEmCadastro {
    background: #f7f7f7;
    border: 1px solid #e6e8ea;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 0;
    font-size: 1.07rem;
    > h3 {
        text-align: center;
        margin: 0;
        font-size: 20px;
    }
    > table {
        margin-top: 10px;
        width: 100%;
        tr {
            line-height: 2.5;
            a {
                color: #6759f2;
                display: inline-block;
                width: 100%;
            }
            &:not(:last-child) {
                > td {
                    border-bottom: 1px solid #e6e8ea;
                }
            }
            .quantidade {
                text-align: right;
            }
            .link {
                text-decoration: none;
            }
            &:hover {
                .link {
                    text-decoration: underline;
                }
            }
        }
    }
}
