@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.btnAccent {
    margin-left: 1rem;
    outline: none;
    text-decoration: none;
    border: 2px solid $cor-acento-3;
    border-radius: 3px;
    padding: $spacing-2x $spacing-3x;
    background-color: $cor-contraste-3;
    color: $cor-acento-3;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: $cor-acento-3;
        color: $cor-contraste-3;
    }

    &:disabled {
        border-color: $cor-contraste-9;
        color: $cor-contraste-9;
        background-color: transparent;
    }
}