@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.timeline {
    width: 100%;
    position: relative;

    &Line {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .verticalLine {
            width: 1px;
            height: 100%;
            z-index: 0;
            background: #586571;
        }

        .hoje {
            background: #586571;
            border-radius: 100px;
            font-size: 10px;
            color: #ffffff;
            padding: 5px 10px;
        }
    }

    &Items {
        padding-top: 3ex;
        display: flex;
        flex-direction: column;

        .timelineItem {
            display: flex;
            flex-direction: row;

            .cardMovimentacao {
                margin-top: 2ex;
                width: calc(50% - 20px);

                border: 1px solid #dadada;
                border-radius: 4px;
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
                background-color: #ffffff;

                padding: $spacing-2x $spacing-3x;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    right: -6px;
                    top: 12px;
                    width: 10px;
                    height: 10px;
                    background-image: linear-gradient(45deg, transparent 49%, white 50%);
                    transform: rotate(45deg);
                    border-top: solid 1px #dadada;
                    border-right: solid 1px #dadada;
                }

                .badge {
                    display: inline-block;
                    padding: 0px 10px;
                    text-transform: uppercase;
                    background-color: $cor-acento-5;
                    color: #ffffff;
                    font-size: 0.8em;
                    border-radius: 4px;
                    margin-right: 5px;
                    height: 1.8rem;
                }

                .bolinhaTimeline {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    right: -29px;
                    top: 9px;
                    border-radius: 50%;

                    background: #2e343b;
                    border: 4px solid $cor-acento-5;
                    box-sizing: border-box;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.108696);
                }

                .movimentacaoData {
                    font-size: 12px;
                    line-height: 24px;
                    display: flex;
                }

                .movimentacaoTitulo {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: "Gibson Semibold";
                    word-wrap: break-word;
                }

                .movimentacaoSubtitulo {
                    font-size: 14px;
                    line-height: 14px;
                    margin-top: $spacing-1x;
                    min-height: $spacing-8x;
                }

                .movimentacaoRotulos {
                    margin: 0.7rem 0;
                }
            }

            &.envio {
                flex-direction: row-reverse;

                .cardMovimentacao {
                    &::before {
                        left: -6px;
                        transform: rotate(-135deg);
                    }

                    .bolinhaTimeline {
                        left: -29px;
                        border-color: $cor-acento-2;
                    }

                    .badge {
                        background-color: $cor-acento-2;
                    }
                }
            }
        }
    }
}

.iconeCopy {
    cursor: pointer;
}

.botaoDetalhar {
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 3px;
    padding: $spacing-1x;
    cursor: pointer;
    align-content: end;
    margin-left: auto;

    &:hover {
        background-color: $cor-contraste-5;

        svg {
            fill: $cor-contraste-3;
        }
    }

    svg {
        width: auto;
        height: 20px;
        fill: $cor-contraste-5;
    }
}