@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.detalharAtividade {
    > :not(:first-child) {
        margin-top: $spacing-2x;
    }

    .cardAtividade {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .prefixo {
            font-weight: bold;
            font-size: 1.2em;
            margin-right: $spacing-1x;
        }
        .qualificacao {
            padding: 0.6rem 0 0 0.5rem;
        }
    }
}
