@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.listarPendencias {
    > * {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

.adicionar {
    display: flex;
    justify-content: flex-end;
    margin: $spacing-4x 0;

    a {
        margin-left: 1rem;
        outline: none;
        text-decoration: none;
        border: 2px solid $cor-acento-3;
        border-radius: 3px;
        padding: $spacing-2x $spacing-3x;
        background-color: $cor-contraste-3;
        color: $cor-acento-3;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;
            color: $cor-contraste-3;
        }
    }
}

.marginTop {
    margin-top: 1rem;
}

.alignRight {
    text-align: right;
}

.modalDetalheInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.modalDetalheInfoAutor {
    display: grid;
    grid-template-columns: 12fr;
    gap: 1rem;
}
