@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.texto,
.label {
    font-size: 17px;
    font-weight: bold;
}
.texto {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
}
.alerta {
    margin-bottom: 1.5rem;
    color: red;
    font-size: 1.5rem;
}
.form {
    margin-top: 2rem;
    margin-right: 8rem;
    .campoTitulo {
        align-items: flex-end;
        height: 2.8rem;
    }
    .label {
        width: 8rem;
    }
    .inline {
        flex: 1;
    }
    .campoForm {
        display: flex;
        margin-bottom: 2rem;
    }
    .agendar {
        display: flex;
        .radioAgendar {
            width: 13rem;
        }
        .agendamento {
            display: flex;
            align-items: flex-end;
            margin-bottom: 1rem;
            .labelDataAgendamento {
                margin-right: 1rem;
                font-size: 0.75rem;
                margin-left: -5rem;
            }
            .dataAgendamento {
                height: 3rem;
                width: 8rem;
            }
        }
    }
}
.acoes {
    display: flex;
    justify-content: center;
    .botaoSubmit {
        min-width: max-content;
        max-height: 3rem;
        margin-left: 1rem;
        outline: none;
        text-decoration: none;
        border: 2px solid $cor-acento-3;
        border-radius: 3px;
        padding: $spacing-2x $spacing-3x;
        background-color: $cor-contraste-3;
        color: $cor-acento-3;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;
            color: $cor-contraste-3;
        }
    }
}
