@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.campo {
    margin: 15px 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * {
        width: 100%;
    }
}
.campoCadastro {
    .valorAcoes {
        display: flex;
        height: 100%;
        > *:last-child {
            margin-top: 1rem;
        }
    }
    .valor {
        flex: 1;
        padding-left: 1rem;
        font-size: 14px;

        display: block;
        background-color: #fafafa;
        border-bottom: 1px solid black;
        line-height: 3rem;
        min-height: 3rem;
        color: #545454;
        height: 100%;
    }
}
.campoCheckbox {
    display: flex;
    justify-content: center;
    input {
        margin-right: 1rem;
    }
    > div {
        display: flex;
    }
}
.acoesCampo {
    margin-left: 1rem;
    .botaoInconsistencia.botaoAcao {
        margin-right: 0.5rem;
        .iconeVermelho {
            path:last-child {
                fill: #e30018;
            }
        }
        .iconeAmarelo {
            path:last-child {
                fill: #ffaf5c;
            }
        }
    }
    .botaoAcao {
        display: inline-block;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;
        height: 18px;
        > svg {
            width: 18px;
            height: 18px;
            > path {
                &:last-child {
                    fill: #6759f2;
                }
            }
        }
        &:hover {
            opacity: 0.6;
        }
    }
}

.rotulo {
    font-size: 15px;
    margin-bottom: 1rem;
    display: block;
    //font-weight: bold;
}
.quadroImagem {
    border: 1px solid #e6e8ea;
    border-radius: 10px;
    padding: 1rem;
    > svg {
        width: 5rem;
        height: 5rem;
    }
    .imagem {
        max-height: 300px;
        max-width: 100%;
    }
    .anexoDocumento {
        cursor: pointer;
        text-decoration: none;
        color: black;
        height: 21.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .iconeDocumento {
            width: 18rem;
            height: 15rem;
        }
        .nomeArquivoAnexo {
            text-align: center;
        }
    }
}

.campoImagem {
    margin-top: 10px;
    display: flex;
}
