@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.cardHeaderMensagem {
    .topico {
        display: flex;

        >* {
            flex: 1;
        }
    }

    .buttons {
        text-align: right;
        padding: 15px 30px;

        >* {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.spacer {
    height: 30px;
}

.jsonPre {
    width: 100%;
    overflow-y: auto;
}