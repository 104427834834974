$cor-acento-1: #f6ab2f;
$cor-acento-2: #1665d8;
$cor-acento-3: #6758f3;
$cor-acento-4: #34aa44;
$cor-acento-5: #e6492d;
$cor-acento-6: #556ee6;

$cor-contraste-1: #333333;
$cor-contraste-2: #e5e5e5;
$cor-contraste-3: #ffffff;
$cor-contraste-4: #f8f8f8;
$cor-contraste-5: #9ea0a5;
$cor-contraste-6: #eaedf3;
$cor-contraste-7: #fafafa;
$cor-contraste-8: #f2f2f2;
$cor-contraste-9: #d8d8d8;
$cor-contraste-10: #bdbdbd;
$cor-contraste-11: #ebf5e0;
$cor-contraste-12: #516a35;

$cor-contraste-13: #fbfbfd;

// cores de gráficos:

$cor-grafico-azul: #556ee6;
$cor-grafico-vermelho: #e6492d;
$cor-grafico-verde: #34c38f;
$cor-grafico-laranja: #f6ab2f;
$cor-grafico-cinza-escuro: #828282;
$cor-grafico-cinza-claro: #bdbdbd;
