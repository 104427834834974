.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #099aaa;
    color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 12px 22px 12px 22px;
    text-decoration: none;
    .top {
        text-align: right;
        font-size: 20px;
        opacity: 0.5;

        &.left {
            margin-top: 13px;
            text-align: left;
            opacity: 1;
        }
    }
    .main {
        text-align: center;
        font-family: "Gibson Semibold";
        font-size: 50px;
        line-height: 60px;
        font-weight: 600;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 0;
    }
    .bottom {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        .text {
            display: inline-block;
            width: 100%;
            text-align: center;
            line-height: 34px;
            font-family: "Gibson Light";
            font-weight: 500;
        }
    }
    .arrowButton {
        float: right;
        width: 34px;
        min-width: 34px;
        height: 34px;
        min-height: 34px;
        background: #ffffff;
        opacity: 0.7;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        > svg {
            > path:last-child {
                fill: #099aaa;
            }
        }
    }

    &.orange {
        background-color: #ffba55;

        .arrowButton {
            > svg {
                > path:last-child {
                    fill: #ffba55;
                }
            }
        }
    }

    &.blue {
        background-color: #628fc3;

        .arrowButton {
            > svg {
                > path:last-child {
                    fill: #628fc3;
                }
            }
        }
    }

    &.red {
        background-color: #FF8080;

        .arrowButton {
            > svg {
                > path:last-child {
                    fill: #FF8080;
                }
            }
        }
    }

    &.limeGreen {
        background-color: #66cc66;

        .arrowButton {
            > svg {
                > path:last-child {
                    fill: #66cc66;
                }
            }
        }
    }
    &.big {
        height: 199px;
        padding-bottom: 25px;
        .arrowButton {
            transform-origin: bottom right;
            scale: 1.3;
        }
        .main {
            font-size: 61px;
        }
    }
}
