@import "~@omnijus/common/build/assets/styles/_colors";
@import "~@omnijus/common/build/assets/styles/_dimensions";

.dlq-table {
    small {
        color: $cor-contraste-5;
    }
}

.consulta-mensagens {
    > :not(:first-child) {
        margin-top: $spacing-3x;
    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    gap: 1rem;
}

.classificacoes {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
}

.card {
    display: grid;
    grid-template-columns: 1fr 11fr;
    gap: 1rem;
}

.folder {
    display: grid;
    grid-template-columns: 1fr 11fr;
}

.icon {
    margin-top: 1rem;

    path {
        fill: rgb(57, 77, 143);
    }
}

.folderIcon {
    align-self: center;
}

.alignRight {
    text-align: right;
}

.prioridade {
    color: rgb(214, 45, 98);
}

.prioridadeAlignRight {
    color: rgb(214, 45, 98);
    text-align: right;
}

.analise {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 1rem;
    margin-top: 1rem;
}

.botoesAnaliseRotulo {
    display: grid;
    grid-template-columns: repeat(3, 4fr);
    gap: 1rem;
    margin-top: 1rem;
}

.botoesAnaliseRespostas {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    gap: 1rem;
    margin-top: 1rem;
}

.marginBottom {
    margin-bottom: 1rem;
}

.botaoAprovar {
    background-color: #4caf50;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #4caf50, $amount: 5);
    }
}

.botaoReprovar {
    background-color: #7400ff;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #7400ff, $amount: 5);
    }
}

.botaoSair {
    background-color: #d62755;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #d62755, $amount: 5);
    }
}

.marginTop {
    margin-top: 1rem;
}

.marginRight {
    margin-right: 1rem;
}

.rotulos {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    gap: 0.5rem;
    text-align: center;
    align-items: center;

    .rotulo {
        padding: 0.5rem 0;
        color: white;
        border-radius: 1rem;
        border: 0.1rem solid #ddd;
    }
}

.botaoEsmaecido {
    background-color: #cacaca;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    outline: none;
}

.barraAcao {
    display: flex;
    margin-top: $spacing-4x;
    margin-bottom: $spacing-4x;
    justify-content: flex-end;
}

.botoesAcao {
    >* {
        margin-left: 1rem;
    }

    .botaoAcao {
        min-width: max-content;
        max-height: 3rem;
        outline: none;
        text-decoration: none;
        border: 2px solid $cor-acento-3;
        border-radius: 3px;
        padding: $spacing-2x $spacing-3x;
        background-color: $cor-contraste-3;
        color: $cor-acento-3;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;
            color: $cor-contraste-3;
        }
    }
}

.cardMensagem {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .uploadDocumento {
        justify-self: flex-end;

        .uploadIcon {
            text-align: center;
        }
    }
}