@import "assets/styles/_dimensions";

.form {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem 0.6rem;
}
.submit {
    float: right;
    margin-top: 2rem;
    width: 10rem;
}
