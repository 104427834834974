@import "assets/styles/_dimensions";

.adicionarAtividade {
    .formulario {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $spacing-4x;
    }
    .cards {
        > :not(:last-child) {
            margin-bottom: 2em;
        }
    }
    .linhaSubmit {
        display: flex;
        justify-content: flex-end;
        > * {
            margin: 0 0 0 3em;
        }
    }
}
