.checkboxAreas {
    display: flex;
    flex-direction: column;
    .opcoes {
        display: flex;
        align-items: center;
        flex: 1;
        :not(:last-child) {
            margin-right: 1rem;
        }
    }
}
