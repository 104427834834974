@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.cardFiltros {
    .header {
        padding: $spacing-4x $spacing-6x;

        .titulo {
            font-weight: bold;
            font-size: 1.2em;
            margin-left: $spacing-3x;
        }
    }

    .filtrosWrapper {
        margin: $spacing-3x $spacing-3x 0 $spacing-3x;
        padding-bottom: $spacing-3x;
        overflow: visible;

        .filtros {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: $spacing-3x;

            .acoes {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                grid-column: span 4;

                button {
                    width: 10rem;
                    max-height: 3.3rem;
                }
                &.inline {
                    grid-column: span 1;
                    grid-column-start: 4;
                }
            }
        }
    }
}
