@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.detalhar-template{
    > :not(:first-child){
        margin-top: $spacing-2x;
    }

    .template{
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .prefixo{
            font-weight: bold;
            font-size: 1.2em;
            margin-right: $spacing-1x;
        }
    }

    .acao-voltar{
        display: flex;
        justify-content: flex-end;
    }
}