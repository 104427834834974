@import "assets/styles/_colors";
@import "assets/styles/_dimensions";

.omnijus-card {
    background-color: $cor-contraste-3;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid $cor-contraste-6;
    width: 100%;

    .header {
        border-bottom: 1px solid $cor-contraste-6;
        display: flex;
        align-items: center;
        padding: $spacing-2x $spacing-4x;

        .collapse {
            margin-left: auto;
            margin-right: $spacing-2x;

            .button {
                text-transform: uppercase;
                border: none;
                background-color: transparent;
                outline: none;
                cursor: pointer;

                .text {
                    margin-right: $spacing-1x;
                }
            }
        }
    }

    .body{
        padding: $spacing-2x $spacing-4x;
    }

    .body-fixed {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        :nth-child(0) {
            flex: 1;
        }
    }

    .body-collapsable {
        overflow: visible;
        transition: max-height 150ms ease-out;

        &.collapsed {
            max-height: 0 !important;
            overflow: hidden;
        }
    }

    .body-loading {
        background: url("../../assets/images/loading.svg") center center no-repeat;
        background-size: 40px 40px;
    }
}
