@import "assets/styles/_dimensions";

.criar-editar-template{
    .cards-wrapper > :not(:first-child){
        margin-top: $spacing-2x;
    }

    .formulario{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spacing-4x;
    }

    .acoes{
        display: flex;
        justify-content: flex-end;
        gap: $spacing-2x;
    }

    .error{
        color: red;
    }
}